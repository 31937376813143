/**
** JAVASCRIPTS
** Name: Apply
********************************************************************************/

(function() {
  'use strict';


  if(document.querySelector('.js-apply')) {


    /**
    ** Variables
    ****************************************/

    var checkpoint = 0;
    var ticking    = false;


    /**
    ** Functions
    ****************************************/

    function setApplyState() {
      checkpoint = document.querySelector('.js-apply').getBoundingClientRect().top + document.querySelector('.js-apply').offsetHeight - window.innerHeight;

      if(checkpoint > 0) {
        if(!document.querySelector('.js-apply').classList.contains('is-fixed')) {
          document.querySelector('.js-apply').classList.add('is-fixed');
        }
      }
      else {
        if(document.querySelector('.js-apply').classList.contains('is-fixed')) {
          document.querySelector('.js-apply').classList.remove('is-fixed');
        }
      }
    }


    /**
    ** Events
    ****************************************/

    // Scroll
    window.addEventListener('scroll', ()=>{

      if (!ticking) {
        window.requestAnimationFrame(function() {
          setApplyState();
          ticking = false;
        });
      }

      ticking = true;
    });

    // Resize
    window.addEventListener('resize', ()=>{
      if (!ticking) {
        window.requestAnimationFrame(function() {
          setApplyState();
          ticking = false;
        });
      }

      ticking = true;
    });

    // Init
    window.addEventListener('load', ()=> {
      setApplyState();
    });


  }

})();
