/**
** JAVASCRIPTS
** Name: Background
********************************************************************************/

(function() {
  'use strict';


  if(document.querySelector('.js-background-start') && document.querySelector('.js-background-end')) {


    /**
    ** Variables
    ****************************************/

    let root = document.documentElement;

    const $html = document.documentElement;
    const delta = 400;

    var scrollPos   = window.pageYOffset;
    var startOffset = 0;
    var endOffset   = 0;
    var ticking     = false;


    /**
    ** Functions
    ****************************************/

    function updateVariables() {
      scrollPos   = window.pageYOffset;
      startOffset = document.querySelector('.js-background-start').getBoundingClientRect().top + window.pageYOffset - window.innerHeight / 2;
      endOffset   = document.querySelector('.js-background-end').getBoundingClientRect().top + window.pageYOffset + window.innerHeight / 2;
    }

    function setBackgroundState(scroll) {
      if(scroll > startOffset && scroll < endOffset) {
        if(scroll < startOffset + delta) {
          var opacity = (scroll - startOffset) / delta;
          root.style.setProperty('--background-opacity', opacity);
        }
        else if(scroll > endOffset - delta) {
          var opacity = (endOffset - scroll) / delta;
          root.style.setProperty('--background-opacity', opacity);
        }
        else {
          root.style.setProperty('--background-opacity', 1);
        }
      }
      else {
        root.style.setProperty('--background-opacity', 0);
      }
    }


    /**
    ** Events
    ****************************************/

    // Scroll
    window.addEventListener('scroll', ()=>{
      scrollPos = window.pageYOffset;

      if (!ticking) {
        window.requestAnimationFrame(function() {
          setBackgroundState(scrollPos);
          ticking = false;
        });
      }

      ticking = true;
    });

    // Resize
    window.addEventListener('resize', ()=>{
      if (!ticking) {
        window.requestAnimationFrame(function() {
          updateVariables();
          ticking = false;
        });
      }

      ticking = true;
    });

    // Init
    window.addEventListener('load', ()=> {
      root.style.setProperty('--background-opacity', 0);
      updateVariables();
    });


  }

})();
