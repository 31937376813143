/**
** JAVASCRIPTS
** Name: Overlay
********************************************************************************/

(function() {
  'use strict';


  if(document.querySelector('.js-overlay') && document.querySelector('.js-overlay-close')) {


    /**
    ** Variables
    ****************************************/

    const $overlay = document.querySelector('.js-overlay');
    const $close   = document.querySelector('.js-overlay-close');


    /**
    ** Events
    ****************************************/

    document.querySelector('.js-overlay-open').addEventListener('click', (event)=>{
      event.preventDefault();
      $overlay.classList.add('is-active');
    });

    $close.addEventListener('click', (event)=>{
      event.preventDefault();
      $overlay.classList.remove('is-active');
    });


  }

})();
