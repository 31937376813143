/**
** JAVASCRIPTS
** Name: Header
********************************************************************************/

(function() {
  'use strict';


  if(document.querySelectorAll('.js-header').length > 0) {


    /**
    ** Variables
    ****************************************/

    const $html  = document.documentElement;
    const $open  = document.querySelector('.js-header-open');
    const $close = document.querySelector('.js-header-close');

    var offsetTop     = 1;
    var lastScrollPos = 0;
    var ticking       = false;


    /**
    ** Functions
    ****************************************/

    function setHeaderState(scrollPos, direction) {

      if(scrollPos >= offsetTop) {
        $html.classList.add('header-fixed');
      }
      else {
        $html.classList.remove('header-fixed');
      }

      if(direction > 0) {
        $html.classList.add('header-hidden');
      }
      else {
        $html.classList.remove('header-hidden');
      }
    }


    /**
    ** Events
    ****************************************/

    // Open
    $open.addEventListener('click', (event)=>{
      event.preventDefault();
      $html.classList.add('header-open');
    });

    // Close
    $close.addEventListener('click', (event)=>{
      event.preventDefault();
      $html.classList.remove('header-open');
    });

    // Scroll
    window.addEventListener('scroll', ()=>{
      var scrollPos = window.pageYOffset || document.documentElement.scrollTop;
      var direction = scrollPos > lastScrollPos ? 1 : -1;

      if (!ticking) {
        window.requestAnimationFrame(function() {
          setHeaderState(scrollPos, direction);
          ticking = false;
        });
      }

      lastScrollPos = (scrollPos <= 0) ? 0 : scrollPos;
      ticking = true;
    });


  }

})();
