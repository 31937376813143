/**
** JAVASCRIPTS
** Name: Footer
********************************************************************************/

(function() {
  'use strict';


  if(document.querySelectorAll('.js-footer').length > 0) {


    /**
    ** Variables
    ****************************************/

    const $sections = document.querySelectorAll('.js-footer-section');

    var ticking = false;


    /**
    ** Functions
    ****************************************/

    function setSectionsState() {
      if(window.outerWidth >= 960) {
        $sections.forEach(($section) => {
          if(!$section.hasAttribute("open")) {
            $section.setAttribute("open", "open");
          }
        });
      }
      else {
        $sections.forEach(($section) => {
          if($section.dataset.open != "true" && $section.hasAttribute("open")) {
            $section.removeAttribute("open");
          }
        });
      }
    };


    /**
    ** Events
    ****************************************/

    // Resize
    window.addEventListener('resize', ()=>{
      if (!ticking) {
        window.requestAnimationFrame(function() {
          setSectionsState();
          ticking = false;
        });
      }

      ticking = true;
    });

    // Init
    setSectionsState();


  }

})();
